export const STORAGE_KEY = 'cvc/lastSelectedModel';
export const MODEL_PREFIX = '/cvc/model/';
export const DEFAULT_MODEL = 'nansy-v2.5';
type NansyV2 = 'nansy-v2';
type NansyV2Half = 'nansy-v2.5';
type NansyV27 = 'nansy-v2.7';
type NansyV28 = 'nansy-v2.8';
type NansyFineTune = `${NansyV2 | NansyV2Half | NansyV27 | NansyV28}-${string}`;
type NansyHarmonicToken = 'nansy-harmonic-token';
type NansyHarmonicTokenInterpolation = `${NansyHarmonicToken}-interpolation`;
export type ModelType =
  | NansyV2
  | NansyV2Half
  | NansyV27
  | NansyV28
  | NansyFineTune
  | 'speaker-control'
  | NansyHarmonicToken
  | NansyHarmonicTokenInterpolation;
// 추후 localStorage에 저장하는 데이터로 변경 로직 대비, 최상단에 비치 될 아이템들 저장
export const MODEL_LABEL: Record<string, string> = {
  'nansy-v2-jeongnyeon': 'Nansy V2 Jeongnyeon KTR',
};
export const MODEL_DESCRIPTIONS: { [key in ModelType]: string } = {
  'nansy-v2': '수퍼톤의 2세대 모델입니다. Voice Conversion 기능을 지원합니다.',
  'nansy-harmonic-token':
    '1세대 모델의 개선된 버전입니다. Voice Converison 기능을 지원합니다.',
  'speaker-control': '성별과 나이를 바꾸는 데에 특화된 구 모델입니다.',
  'nansy-v2-interpolation':
    '2세대 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  'nansy-harmonic-token-interpolation':
    '1세대 모델의 개선된 버전에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  'nansy-v2-maskgirl':
    '마스크걸에 맞춰 튜닝한 수퍼톤의 가장 최신 모델입니다. Voice Conversion 기능을 지원하는 모드입니다.',
  'nansy-v2-maskgirl-interpolation':
    '마스크걸에 맞춰 튜닝한 수퍼톤의 가장 최신 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  'nansy-v2-amelia': '아이유노 Polish POC 용 nansy-v2 finetuned 모델',
  'nansy-v2-aleksandra': '아이유노 Polish POC 용 nansy-v2 finetuned 모델',
  'nansy-v2-changdong-toast': '창동토스트 nansy-v2 finetuned 모델',
  'nansy-v2-jpn': '아이유노 Japanese POC 용 nansy-v2 finetuned 모델',
  'nansy-v2-flint': '플린트 POC용 nansy-v2 finetuned 모델',
  'nansy-v2-jeongnyeon': '정년이 nansy-v2 finetuned 모델',
  'nansy-v2-jeongnyeon-interpolation':
    '정년이 nansy-v2 finetuned 모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  'nansy-v2-jeongnyeon2': '정년이 nansy-v2 finetuned 모델2',
  'nansy-v2-kakaogames': '카카오 게임즈 POC용 파인튜닝 모델',
  'nansy-v2-hungary': '아이유노 헝가리 POC용 파인튜닝 모델',
  'nansy-v2-mrbeast': 'MrBeast POC용 파인튜닝 모델',
  'nansy-v2-pitchstable': 'Pitch에 더 강인한 버전',
  'nansy-v2-iyuno-mand': '아이유노 Mandarin POC용 파인튜닝 모델',
  'nansy-v2-iyuno-mand2': '아이유노 Mandarin POC용 파인튜닝 모델2',
  'nansy-v2-death-stranding': 'Death Stranding POC용 파인튜닝 모델',
  'nansy-v2.5': 'Nansy 2.5모델입니다. Voice Conversion 기능을 지원합니다.',
  'nansy-v2.5-jeongnyeon-SYE': '정년이 nansy-v2.5 finetuned 모델',
  'nansy-v2.7': 'Nansy 2.7모델입니다. Voice Conversion 기능을 지원합니다.',
  'nansy-v2.7-jeongnyeon': '정년이 nansy-v2.7 finetuned 모델',
  'nansy-v2.7-jeongnyeon-JEC': '정년이 nansy-v2.7 finetuned 모델',
  'nansy-v2.7-interpolation':
    'Nansy 2.7모델에서 여러 목소리를 섞어서 Voice Conversion할 수 있게 하는 모드입니다.',
  'nansy-v2.8': '수퍼톤의 가장 최신 모델입니다.',
};

export const ParameterType = {
  Checkbox: 'checkbox',
  Slider: 'slider',
  Ratio: 'ratio',
} as const;
type Parameter = (typeof ParameterType)[keyof typeof ParameterType];
export interface CheckboxParameter {
  type?: Parameter;
  name: string;
  label: string;
  tooltip: string;
  defaultValue: boolean;
}
export interface SliderParameter
  extends Omit<CheckboxParameter, 'defaultValue'> {
  defaultValue: number;
  minValue: number;
  maxValue: number;
  step: number;
  minLabel?: string;
  maxLabel?: string;
  isToggleable?: boolean;
  defaultToggle?: boolean;
  valueFormatter?: (value: number) => string;
  disabled?: boolean;
}

const parameterMap: Record<string, CheckboxParameter | SliderParameter> = {
  keep_source_pitch: {
    type: ParameterType.Checkbox,
    name: 'keep_source_pitch',
    label: 'Keep Source Pitch',
    tooltip: '원본 소스 피치 복제',
    defaultValue: true,
  },

  keep_source_pitch_variance: {
    type: ParameterType.Checkbox,
    name: 'keep_source_pitch_variance',
    label: 'Keep Source Pitch Variance',
    tooltip: '원본 소스 음정 편차 복제',
    defaultValue: true,
  },

  semitone: {
    name: 'semitone',
    label: 'Pitch Shift',
    tooltip: '반음 단위로 수정',
    defaultValue: 0,
    minValue: -24,
    maxValue: 24,
    step: 1,
  },

  pitch_variance: {
    name: 'pitch_variance',
    label: 'Pitch Variance',
    tooltip: '음정 편차 조절',
    defaultValue: 1,
    minValue: 0,
    maxValue: 2,
    step: 0.1,
    minLabel: 'static',
    maxLabel: 'dynamic',
  },

  scale_factor: {
    name: 'scale_factor',
    label: 'Tempo Change',
    tooltip: '템포 조절',
    defaultValue: 1,
    minValue: 0.5,
    maxValue: 2,
    step: 0.1,
    minLabel: 'slow',
    maxLabel: 'fast',
  },

  temperature: {
    name: 'temperature',
    label: 'Target Similarity',
    tooltip: '타겟 유사도, 낮을수록 결과물이 랜덤하게 변합니다.',
    defaultValue: 2,
    minValue: 0,
    maxValue: 10,
    step: 1,
  },

  breathy_db: {
    name: 'breathy_db',
    label: 'Breath Control',
    tooltip: '호흡 조절, 높을수록 호흡이 많아집니다.',
    defaultValue: 0,
    minValue: 0,
    maxValue: 10,
    step: 1,
    isToggleable: false,
    defaultToggle: false,
  },

  distortion_db: {
    name: 'distortion_db',
    label: 'Clarity Control',
    tooltip: '명료도 조절',
    defaultValue: 0,
    minValue: 0,
    maxValue: 10,
    step: 1,
    isToggleable: false,
    defaultToggle: false,
  },

  whisper_ratio: {
    name: 'whisper_ratio',
    label: 'Whisper',
    tooltip: '속삭임 정도, 높을수록 더 속삭이는 것처럼 변합니다.',
    defaultValue: 0,
    minValue: 0,
    maxValue: 10,
    step: 1,
    isToggleable: false,
    defaultToggle: false,
  },

  tgt_gender: {
    name: 'tgt_gender',
    label: 'Target Gender',
    tooltip: '성별',
    defaultValue: 0,
    minValue: -15,
    maxValue: 15,
    step: 0.1,
    minLabel: 'F',
    maxLabel: 'M',
  },

  tgt_age: {
    name: 'tgt_age',
    label: 'Target Age',
    tooltip: '연령',
    defaultValue: 30,
    minValue: 0,
    maxValue: 100,
    step: 1,
  },

  norm_input_volume_db: {
    name: 'norm_input_volume_db',
    label: 'Input Volume (dB)',
    tooltip: '음량 조절',
    defaultValue: 0,
    minValue: -10,
    maxValue: 10,
    step: 0.1,
  },

  // nansy-v2.5
  speed: {
    name: 'speed',
    label: 'Tempo Change',
    tooltip: '템포 조절',
    defaultValue: 1,
    minValue: 0.5,
    maxValue: 2,
    step: 0.1,
    minLabel: 'slow',
    maxLabel: 'fast',
  },

  pitch_shift: {
    name: 'pitch_shift',
    label: 'Pitch Shift',
    tooltip: '반음 단위로 수정',
    defaultValue: 0,
    minValue: -24,
    maxValue: 24,
    step: 1,
  },

  pitch_dynamic: {
    name: 'pitch_dynamic',
    label: 'Pitch Variance',
    tooltip: '음정 편차 조절',
    defaultValue: 1,
    minValue: 0,
    maxValue: 2,
    step: 0.1,
    minLabel: 'static',
    maxLabel: 'dynamic',
  },

  keep_source_pitch_dynamic: {
    type: ParameterType.Checkbox,
    name: 'keep_source_pitch_dynamic',
    label: 'Keep Source Pitch Variance',
    tooltip: '원본 소스 음정 편차 복제',
    defaultValue: true,
  },

  segment: {
    type: ParameterType.Checkbox,
    name: 'segment',
    label: 'Segment',
    tooltip: 'Source 오디오를 유음 구간 단위로 나누어 처리할지 여부',
    defaultValue: false,
  },

  subharmonic_amplitude: {
    type: ParameterType.Slider,
    name: 'subharmonic_amplitude',
    label: 'Subharmonic Amplitude',
    tooltip: 'Subharmonic Amplitude',
    defaultValue: 0.0,
    minValue: 0.0,
    maxValue: 2.0,
    step: 0.1,
  },

  subharmonic_amplitude_v2_8: {
    type: ParameterType.Slider,
    name: 'subharmonic_amplitude',
    label: 'Subharmonic Amplitude',
    tooltip: 'Subharmonic Amplitude',
    defaultValue: 1.0,
    minValue: 0.0,
    maxValue: 2.0,
    step: 0.1,
  },

  keep_source_subharmonic_amplitude: {
    type: ParameterType.Checkbox,
    name: 'keep_source_subharmonic_amplitude',
    label: 'Keep Source Subharmonic Amp',
    tooltip: '원본 소스 subharmonic amplitude 복제',
    defaultValue: true,
  },
};

const nansyV2Parameters = [
  parameterMap.keep_source_pitch,
  parameterMap.keep_source_pitch_variance,
  parameterMap.semitone,
  parameterMap.pitch_variance,
  parameterMap.scale_factor,
  parameterMap.temperature,
  parameterMap.breathy_db,
];
const speakerControlParameters = [
  parameterMap.tgt_age,
  parameterMap.tgt_gender,
  parameterMap.keep_source_pitch,
  parameterMap.semitone,
  parameterMap.pitch_variance,
  parameterMap.scale_factor,
  parameterMap.temperature,
  parameterMap.breathy_db,
  parameterMap.distortion_db,
  parameterMap.whisper_ratio,
];
const nansyHarmonicTokenParameters = [
  parameterMap.keep_source_pitch,
  parameterMap.semitone,
  parameterMap.pitch_variance,
  parameterMap.scale_factor,
  parameterMap.temperature,
  parameterMap.breathy_db,
  parameterMap.distortion_db,
  parameterMap.whisper_ratio,
];
const nansyHarmonicTokenInterpolationParameters = [
  parameterMap.keep_source_pitch,
  parameterMap.semitone,
  parameterMap.pitch_variance,
  parameterMap.scale_factor,
  parameterMap.temperature,
  parameterMap.breathy_db,
  parameterMap.distortion_db,
  parameterMap.whisper_ratio,
];
const nansyV2_5Parameters = [
  parameterMap.keep_source_pitch,
  parameterMap.keep_source_pitch_dynamic,
  parameterMap.segment,
  parameterMap.pitch_shift,
  parameterMap.pitch_dynamic,
  parameterMap.speed,
  parameterMap.subharmonic_amplitude,
];

const nansyV2_8Parameters = [
  parameterMap.keep_source_pitch,
  parameterMap.keep_source_pitch_dynamic,
  parameterMap.keep_source_subharmonic_amplitude,
  parameterMap.segment,
  parameterMap.pitch_shift,
  parameterMap.pitch_dynamic,
  parameterMap.speed,
  parameterMap.subharmonic_amplitude_v2_8,
];

export const modelParamMap: Record<ModelType, any> = {
  'nansy-v2': nansyV2Parameters,
  'nansy-v2-interpolation': nansyV2Parameters,
  'nansy-v2.5': nansyV2_5Parameters,
  'nansy-v2.5-interpolation': nansyV2_5Parameters,
  'nansy-v2.7': nansyV2_5Parameters,
  'nansy-v2.7-interpolation': nansyV2_5Parameters,
  'nansy-v2.8': nansyV2_8Parameters,
  'nansy-harmonic-token': nansyHarmonicTokenParameters,
  'speaker-control': speakerControlParameters,
  'nansy-harmonic-token-interpolation':
    nansyHarmonicTokenInterpolationParameters,
};
